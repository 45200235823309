/* latin-ext */
@font-face {
  font-family: '__Titillium_Web_4cb4d1';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/87cac5386ae78d2f-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Titillium_Web_4cb4d1';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/2bf82a7bb27b395e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Titillium_Web_4cb4d1';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/928f8fbd0ceb6185-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Titillium_Web_4cb4d1';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/23d8abc69d62f3d0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Titillium_Web_4cb4d1';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/0c1e327e026a9f54-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Titillium_Web_4cb4d1';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/703d9a90a8aabcb9-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Titillium_Web_4cb4d1';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a4f00cf9cbfc4a42-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Titillium_Web_4cb4d1';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/2bb25458ea2620e9-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Titillium_Web_4cb4d1';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/092b00a2f3798ba2-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Titillium_Web_4cb4d1';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/f378bd2abf9e0d48-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Titillium_Web_4cb4d1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/1d769dbdf84e9e05-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Titillium_Web_4cb4d1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/28fac4a6e903645b-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Titillium_Web_Fallback_4cb4d1';src: local("Arial");ascent-override: 119.97%;descent-override: 41.09%;line-gap-override: 0.00%;size-adjust: 94.44%
}.__className_4cb4d1 {font-family: '__Titillium_Web_4cb4d1', '__Titillium_Web_Fallback_4cb4d1'
}

